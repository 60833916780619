@import url('https://use.fontawesome.com/releases/v5.7.2/css/all.css');

.App {
  text-align: center;
  background-color: transparent;
  background-image: url('./starbg.svg');
}

.App-header {
  background-color: transparent;
  min-height: 30vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.brand {
  font-size: 80px;
  font-family: 'Rambla', sans-serif;
  font-weight: 700;
  font-style: italic;
  margin-bottom: 0px; 
  text-align: left;
}

.tagline {
  font-size: 18px;
  font-family: 'Rambla', sans-serif;
  font-weight: 700;
  font-style: italic;
  margin-top: -20px;
  padding-left: 70px; 
  text-align: left;
}

.wallet-button {
  border-top: none !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-left-radius: 10px !important; 
  border-bottom-right-radius: 10px !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
  padding-bottom: 12px !important;
  padding-top: 12px !important;
  box-shadow: none !important;
}

.wallet-button-mobile {
  border-top: none !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-left-radius: 10px !important; 
  border-bottom-right-radius: 10px !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
  padding-bottom: 5px !important;
  padding-top: 5px !important;
  box-shadow: none !important;
}

.wallet-button-address {
  margin-bottom: 0px; 
  font-family: 'Rambla', sans-serif;
  margin-right: 15px; 
  font-size: 22px; 
  box-shadow: none;
}

.wallet-button-address-mobile {
  margin-bottom: 0px; 
  font-family: 'Rambla', sans-serif;
  font-weight: 700;
  font-style: italic;
  margin-right: 15px; 
  font-size: 18px; 
  box-shadow: none;
}

.eth-balance-badge {
  color: black !important;
  padding-top: 8px !important;
  margin-right: 10px !important;
}

.eth-balance-badge-mobile {
  color: black !important;
  padding-top: 8px !important;
  margin-right: 10px !important;
  font-size: 12px !important;
}

.star-balance-badge {
  color: black !important;
  padding-top: 8px !important;
}

.star-balance-badge-mobile {
  color: black !important;
  padding-top: 8px !important;
  font-size: 12px !important;
}

.wallet-button-connect {
  margin-bottom: 0px;
  font-family: 'Rambla', sans-serif;
  font-size: 25px;
}

.wallet-button-connect-mobile {
  margin-bottom: 0px;
  font-family: 'Rambla', sans-serif;
  font-weight: 700;
  font-style: italic;
  font-size: 20px;
}

.modal-title {
  margin-bottom: 0px; 
  font-family: 'Rambla', sans-serif;
  margin-right: 15px;
  font-size: 30px;
}

.modal-desc {
  margin-bottom: 0px; 
  font-family: 'Rambla', sans-serif;
  margin-right: 15px;
  font-size: 20px;
}

.modal-row-header {
  margin-bottom: 0px;
  font-family: 'Rambla', sans-serif;
  margin-left: 30px; 
  font-size: 35px;
}

.link-icon {
  margin-left: 5px; 
  margin-bottom: 2px;
  font-size: 0.75rem; 
  vertical-align: middle;
}

.accordion-button:focus {
  border-color: none !important;
  border: none !important;
  box-shadow: none !important;
  color: #212529 !important;
}

.accordion-button:not(.collapsed) {
  border: none !important;
  color: #212529 !important;
}
