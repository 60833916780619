.Home {
  display: flex;
  background-color: transparent;
}

.tile-wrapper {
  padding: 0px;
  border-radius: 25px;
  margin-bottom: 25px;
  background-color: #ffc107;
  width: 300px;
}

.tile-wrapper-mobile {
  padding: 0px;
  border-radius: 15px;
  margin-bottom: 25px;
  background-color: #ffc107;
  width: 160px;
}

.buy-button-wrapper {
  margin-top: -300px;
  height: 300px;
  backdrop-filter: blur(8px);
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}

.buy-button-wrapper-mobile {
  margin-top: -160px;
  height: 160px;
  backdrop-filter: blur(8px);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.buy-button {
  margin-top: 130px;
}

.buy-button-mobile {
  margin-top: 30px;
}

.buy-button-label-wrapper {

}

.buy-button-label-wrapper-mobile {
  padding-left: 5px !important;
  padding-right: 5px !important; 
}

.buy-button-label {
  font-size: 24px;
  margin-bottom: 5px;
}

.buy-button-label-mobile {
  font-size: 20px;
  margin-bottom: 5px;
}

.tile-star-name-label {
 text-align: left;
 font-size: 18px;
 margin-left: 20px;
 margin-bottom: 0px;
}

.tile-star-name-label-mobile {
 text-align: left;
 font-size: 12px;
 margin-left: 20px;
 margin-bottom: 0px;
}

.tile-star-price-label-wrapper {
  display: flex  !important;
  padding-top: 12px  !important;
  padding-bottom: 10px  !important;
}

.tile-star-price-label-wrapper-mobile {
  display: flex  !important;
  padding-top: 12px  !important;
  padding-bottom: 10px  !important;
  padding-left:  0px  !important;
}

.tile-star-price-label {
  font-size: 18px;
  margin-left: auto;
  margin-right: 10px;
  margin-bottom: 0px;
}

.tile-star-price-label-mobile {
  font-size: 12px;
  margin-left: auto;
  margin-right: 10px;
  margin-bottom: 0px;
}